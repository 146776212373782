@use "variables" as v;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,700;1,900&family=Suez+One&display=swap');
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css" layer(bootstrap);
@import "../../../node_modules/primeng/resources/primeng.min.css" layer(primeng);

:root {
  --brS: 4px;
  --brM: 6px;
  --brL: 10px;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  overflow: hidden;
  background: linear-gradient(150deg, #FF416C, transparent);
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;

  background: rgb(207, 236, 249);
  background: -moz-linear-gradient(top, rgba(207, 236, 249, 1) 0%, rgba(230, 241, 247, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(207, 236, 249, 1) 0%, rgba(230, 241, 247, 1) 100%);
  background: linear-gradient(to bottom, rgba(207, 236, 249, 1) 0%, rgba(230, 241, 247, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cfecf9', endColorstr='#e6f1f7', GradientType=0);
}

strong {
  font-weight: 700;
}

h1, h2, h3 {
  font-weight: 900;
}

h1 {
  font-family: 'Suez One', serif;
  font-weight: 400;
}

.subtitle {
  font-size: 20px;
  text-align: center;
  margin-bottom: 1rem;
}

.dashboard-panel .subtitle {
  text-align: left;
  margin-bottom: 1.5rem;
}

.loading {
  display: none;
  padding: 5px;
}

app-root:empty + .loading {
  display: block;
}

.form-wrapper {
  padding: 100px 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard-panel {
  padding: 1rem 2rem 2rem;
  min-height: calc(100vh - 55px);
  background: url('../img/login-bg-left.svg') no-repeat bottom left;
  background-size: 60%;
}

.card {
  background: #ffffff !important;
  border: 0;
  filter: contrast(100%);

  .card-footer {
    border: 0;
  }
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #0d6efd;
}

.dropdown-item {
  position: relative;
}

.p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-thead>tr>th {
  border: 0;
  word-break: break-word;
}

.alert {
  font-size: 12px;
  padding: 5px;
}

.alert-danger {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  border: 0;
  font-weight: 400;
  color: v.$alert-color;
}

.card {
  background: #ffffff !important;
  border: 0;
  filter: contrast(100%);

  .card-footer {
    border: 0;
  }
}

.p-datatable .p-datatable-tbody>tr,
.p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-thead>tr>th {
  border: 0;
}

.p-datatable .p-datatable-thead>tr>th:first-child {
  border-radius: 3px 0 0 0;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
  border-radius: 0 3px 0 0;
}

.p-datatable .p-paginator-bottom {
  border-radius: 0 0 3px 3px;
  border: 0;
}

.p-dialog .p-dialog-header,
.p-dialog .p-dialog-footer,
.p-dialog {
  border: 0;
}

.p-accordion .p-accordion-content,
.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 0;
}

.filters {
  .p-calendar .p-inputtext {
    border: 0;
  }
}

.alert {
  font-size: 12px;
  padding: 2px;
}

.alert-danger {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  border: 0;
  font-weight: 400;
  color: v.$alert-color;
}

@media (max-width: 768px) {
  .p-datatable .p-datatable-tbody>tr {
    border-radius: 3px;
  }
}


/* ----- OVERRIDES ----- */

.logged-out .p-menubar-end {
  display: none;
}

.p-button {
  background: v.$primary-color;
  color: #ffffff;
  border: 0;
  text-decoration: none;

  * {
    text-decoration: none;
    border: 0;
  }
}

footer .p-button {
  background: #222222;

  &:hover {
    background: #111111;
  }
}

.p-button:enabled:hover {
  background: v.$primary-color-hover;
}


.p-menubar-root-list {
  margin-left: 3rem !important;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link,
.p-menubar .p-menuitem-link {
  // reverted Mitja's work
  // border-radius: 40px !important;
  // margin-right: .5rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover,
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: v.$primary-color;

  .p-menuitem-text, .p-menuitem-link {
    color: #ffffff !important;
  }
}

.p-menubar li:hover .p-menuitem-link {
  color: #ffffff !important;
}

.p-progressbar {
  background: rgba(0,0,0,.1);
  border-radius: 30px;
  color: #fff;
}

.p-progressbar .p-progressbar-value {
  background: v.$primary-color;
}

.p-progressbar .p-progressbar-label {
  color: #ffffff;
}

.p-dropdown {
  border: 0;
}

.p-inputtext.ng-dirty.ng-invalid {
  border: 1px solid #a6a6a6;
}

.success {
  color: #8BAF6C;
}

.fail {
  color: v.$primary-color;
}

.gray-cancel-button {
  background: #607D8B;;
}

.gray-cancel-button:enabled:hover {
  background: #546E7A;
  border-color: #546E7A;
}

.confirm-button {
  background: #34a835;
}

.confirm-button:enabled:hover {
  background: #107D11;
  border-color: #107D11;
}

.cancel-button {
  background: #e91224;
}

.cancel-button:enabled:hover {
  background: #c01120;
  border-color: #c01120;
}
